import React from 'react';
import { connect } from 'react-redux';
import { openInput, setLocationByIATACode } from '../../../actions/baseActions';
import { selectToLocation } from '../../../actions/flightActions';
import { FLIGHT_SELECT_TO_LOCATION } from '../../../actions/types';
import Helper from '../../modules/Helper';
import AirportSelect from '../Common/AirportSelect';
import { IAirportItem } from '../Common/AirportSelect/Item';

type AirportSelectToProps = {
	name: string;
	selectedToLocation?: IAirportItem;
	autoJump?: boolean;
	preventSetInputBoxClassName?: boolean;
	selectToLocation: (item: IAirportItem | object) => void;
	openInput: (name?: string | null) => void;
	setLocationByIATACode: (location: string, type: string) => void;
};

class AirportSelectTo extends React.Component<AirportSelectToProps> {
	onSelect = (location: IAirportItem | object) => {
		this.props.selectToLocation(location);
		if (this.props.autoJump) {
			this.props.openInput('date-from');
		} else {
			this.props.openInput();
		}
	};

	componentDidMount() {
		const parsed = Helper.parseQueryString(window.location.search, true);
		if (
			!!parsed.destcode &&
			parsed.destCode !== 'undefined' &&
			parsed.destcode.length === 3 &&
			parsed.destcode !== 'None'
		) {
			const destcode = parsed.destcode as string;
			this.props.setLocationByIATACode(destcode.toUpperCase(), FLIGHT_SELECT_TO_LOCATION);
		}
	}

	componentDidUpdate(prevProps: AirportSelectToProps) {
		const { selectedToLocation, name } = this.props;

		if (!Helper.isEmpty(selectedToLocation) && selectedToLocation !== prevProps.selectedToLocation) {
			setTimeout(() => {
				document.getElementById(name + '-input')?.blur();
			}, 1);
		}
	}

	render() {
		const name = this.props.name;
		return (
			<AirportSelect
				i18nValidationDropDownKey="validation.flights.toLocationSelectDropDown"
				preventSetInputBoxClassName={this.props.preventSetInputBoxClassName}
				icon="icon-departure-copy-2"
				placeholder={Helper.trans('main.flights.toInputPlaceholder')}
				selected={this.props.selectedToLocation}
				name={name}
				onSelect={this.onSelect}
			/>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	selectedToLocation: state.flight.selectedToLocation,
	selectedFromLocation: state.flight.selectedFromLocation,
	autoJump: state.base.autoJump,
});

export default connect(mapStateToProps, { selectToLocation, openInput, setLocationByIATACode })(AirportSelectTo);
