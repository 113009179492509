import { ItemType, pricingTypes } from 'app/modules/Constants';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { selectPricingType } from '../../../actions/flightActions';
import BasicDropDown from '../Common/BasicDropDown';

type PricingTypesProps = {
	selectedPricingType?: ItemType;
	selectPricingType: (item: ItemType) => void;
};

const PricingTypes: FC<PricingTypesProps> = (props) => {
	const { t } = useTranslation();

	const onSelect = (item: ItemType) => {
		props.selectPricingType(item);
	};

	return (
		<BasicDropDown
			onSelect={onSelect}
			name="pricing-type"
			selected={props.selectedPricingType}
			placeholder={t('keywords.pricingType')}
			items={pricingTypes}
			testId="pricing-types"
		/>
	);
};

const mapStateToProps = (state: any) => ({
	selectedPricingType: state.flight.selectedPricingType,
});

export default connect(mapStateToProps, { selectPricingType })(PricingTypes);
