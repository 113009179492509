import TranslateString from 'app/components/Common/TranslateString';
import { ItemType } from 'app/modules/Constants';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { openInput } from '../../../actions/baseActions';

type InputDropDownProps = {
	selected?: ItemType;
	placeholder: string;
	openedInput: string;
	name: string;
	items: ItemType[];
	onSelect: (item: ItemType) => void;
	openInput: (name?: string | null) => void;
};
type InputDropDownState = {
	selected: ItemType | undefined;
	in: boolean;
};

class InputDropDown extends React.Component<InputDropDownProps, InputDropDownState> {
	constructor(props: InputDropDownProps) {
		super(props);
		this.state = {
			selected: undefined,
			in: false,
		};
	}

	getSelected() {
		if (this.state.selected && Object.keys(this.state.selected).length) {
			return <TranslateString i18nKey={this.state.selected.label} />;
		}
		if (this.props.selected === undefined) {
			if (this.state.selected && Object.keys(this.state.selected).length) {
				return <TranslateString i18nKey={this.state.selected.label} />;
			}
			return this.props.placeholder;
		} else if (Object.keys(this.props.selected).length) {
			return <TranslateString i18nKey={this.props.selected.label} />;
		} else {
			return this.props.placeholder;
		}
	}

	showItemsList = () => {
		this.setState(
			{
				in: !this.state.in,
			},
			() => {
				if (this.state.in) {
					this.props.openInput(this.props.name);
				} else {
					this.close();
				}
			}
		);
	};
	_onSelect = (item: ItemType) => {
		this.setState(
			{
				selected: item,
				in: false,
			},
			() => {
				this.close();
				if (this.props.onSelect) {
					this.props.onSelect(item);
				}
			}
		);
	};
	getSelectedItem = () => {
		if (this.state.selected && Object.keys(this.state.selected).length) {
			return this.state.selected;
		}
		if (this.props.selected && Object.keys(this.props.selected)) {
			return this.props.selected;
		}
		return undefined;
	};

	// @ts-ignore
	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: InputDropDownProps, nextContext) {
		if (
			nextProps.openedInput !== this.props.openedInput &&
			nextProps.openedInput &&
			nextProps.openedInput !== this.props.name
		) {
			this.setState({
				in: false,
			});
		}
	}

	close = () => {
		this.setState(
			{
				in: false,
			},
			() => {
				this.props.openInput(null);
			}
		);
	};

	render() {
		const selectedItem = this.getSelectedItem();
		const { items } = this.props;
		return (
			<>
				<div
					id={this.props.name}
					className={classnames('basic-drop-down input-basic-drop-down ', {
						'open-input': this.props.openedInput === this.props.name,
					})}
				>
					<div onClick={this.showItemsList} className="input-control">
						<span className="d-name">{this.getSelected()}</span>
					</div>
					<CSSTransition
						in={this.props.openedInput === this.props.name}
						timeout={1}
						unmountOnExit={true}
						classNames="fade"
					>
						<div className="body">
							{items.map((item: ItemType, key: number) => {
								return (
									<div
										onClick={() => this._onSelect(item)}
										key={key}
										className={classnames('item', {
											selected: !!(
												selectedItem &&
												Object.keys(selectedItem).length &&
												selectedItem.value === item.value
											),
										})}
									>
										{<TranslateString i18nKey={item.label} />}
									</div>
								);
							})}
						</div>
					</CSSTransition>
				</div>
			</>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	openedInput: state.base.openedInput,
});

export default connect(mapStateToProps, { openInput })(InputDropDown);
