import dynamic from 'next/dynamic';
import React, { FC } from 'react';
import Trans from '../Common/Trans';

const companies = [
	'/images/companies/kayak.png?v1.1',
	'/images/companies/priceline.png?v1.1',
	'/images/companies/travelocity.png?v1.1',
	'/images/companies/smartfares.png?v1.1',
	'/images/companies/hotwire.png?v1.1',
	'/images/companies/orbitz.png?v1.1',
	'/images/companies/skyscanner.png?v1.1',
	'/images/companies/expedia.png?v1.1',
];
const deltaCompanies = [
	'/images/companies/delta.png?v1.1',
	'/images/companies/southwest.png?v1.1',
	'/images/companies/united.png?v1.1',
	'/images/companies/alaska.png?v1.1',
	'/images/companies/american-airlines.png?v1.1',
	'/images/companies/jetblue.png?v1.1',
	'/images/companies/hawaiian.png?v1.1',
	'/images/companies/frontier.png?v1.1',
];
type FlightCompaniesProps = {
	useHeadline?: boolean;
	isAirlines?: boolean;
	description?: string;
	page?: any;
};

const CompanyImage = dynamic(() => import('../Common/CompanyImage'), { ssr: true });

const FlightCompanies: FC<FlightCompaniesProps> = (props) => {
	const { description, isAirlines, useHeadline, page } = props;
	return (
		<div className="text-center" id="companies">
			{useHeadline ? (
				<h2 className="description mb-2">
					{page?.h2 ? page?.h2 : (<Trans i18nKey={description} />)}
				</h2>
			) : (
				<div className="description mb-2">
					{page?.h2 ? page?.h2 : (<Trans i18nKey={description} />)}
				</div>
			)}
			<ul>
				{isAirlines
					? deltaCompanies.map((companyImage, key) => {
							return (
								<li key={key}>
									<CompanyImage src={companyImage} />
								</li>
							);
					  })
					: companies.map((companyImage, key) => {
							return (
								<li key={key}>
									<CompanyImage src={companyImage} />
								</li>
							);
					  })}
			</ul>
		</div>
	);
};
export default React.memo(FlightCompanies);
