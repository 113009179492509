import Image from 'next/image';
import { useEffect, useState } from 'react';

function FlightBackgroundPics() {
	const [width, setWidth] = useState<number>(0);

	useEffect(() => {
		if (typeof window != 'undefined') {
			setWidth(document.body.clientWidth);
			window.addEventListener('resize', checkWidth);
		}
		return () => {
			window.removeEventListener('resize', checkWidth);
		};
	}, []);

	function checkWidth() {
		setWidth(document.body.clientWidth);
	}
	if (width < 1200) {
		return <></>;
	}
	return (
		<div id="flight-background-pics">
			<Image alt="compass small" src="/images/compass-small.png?v1.0" width={250} height={200} />
			<Image alt="airplane small" src="/images/airplane-small.png?v1.0" width={200} height={300} />
			<Image alt="globe small" src="/images/globe-small.png?v1.0" width={220} height={250} />
		</div>
	);
}
export default FlightBackgroundPics;
