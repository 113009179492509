import Helper from 'app/modules/Helper';
import classnames from 'classnames';
import { addDays, isAfter, isDate } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';
import { openInput } from '../../../actions/baseActions';
import { selectFromDate, selectToDate } from '../../../actions/flightActions';
import DatePicker from '../Common/DatePicker';
import FadeTransition from '../Common/FadeTransition';

type DatePickerToProps = {
	selectedToDate: Date;
	selectedFromDate: Date;
	name: string;
	openedInput?: string;
	openedChildInput?: string;
	autoJump?: boolean;
	preventSetInputBoxClassName?: boolean;
	lng: string;
	openInput: (name?: string) => void;
	selectToDate: (date: Date) => void;
	selectFromDate: (date: Date) => void;
};

type DatePickerToState = {
	in: boolean;
};

class DatePickerTo extends React.Component<DatePickerToProps, DatePickerToState> {
	constructor(props: DatePickerToProps) {
		super(props);
		this.state = {
			in: false,
		};
	}

	onSelectDate = (to: Date, keyboardNavigation = false) => {
		let from = this.props.selectedFromDate;

		if (!isDate(from)) {
			from = new Date();
		} else {
			from = new Date(from);
		}
		if (!isDate(to)) {
			to = addDays(from, 1);
		} else {
			to = new Date(to);
		}

		if (isAfter(from, to)) {
			from = new Date(to);
		}

		this.props.selectFromDate(from);
		this.props.selectToDate(to);

		if (!keyboardNavigation) {
			this.openCalendar(true);
		}
		const w = window.innerWidth;
		if (w < 700) {
			Helper.doScrolling('#filter-form', 500);
		}
	};

	openCalendar = (close?: any) => {
		close = close || false;
		close = Helper.isBoolean(close) && close;

		this.setState(
			{
				in: close ? false : !this.state.in,
			},
			() => {
				if (close) return this.props.openInput('');
				if (this.state.in) {
					this.props.openInput(this.props.name);
				} else {
					this.props.openInput('');
				}
			}
		);
	};

	componentDidUpdate(prevProps: DatePickerToProps, prevState: DatePickerToState) {
		if (Helper.isEmpty(this.props.openedInput) && prevState.in) {
			this.setState({
				in: false,
			});
		}
	}

	render() {
		const { lng, name, selectedToDate, selectedFromDate, openedInput, preventSetInputBoxClassName } = this.props;
		const locale = Helper.getCurrentLocale(lng);

		return (
			<div
				id={name}
				className={classnames('flex-1', {
					'open-input': openedInput === name,
					'input-box': !preventSetInputBoxClassName,
				})}
			>
				<div
					className={classnames('input-control justify-content-between filled', {
						focused: openedInput === name,
					})}
					aria-label={selectedToDate.toLocaleString(locale, {
						weekday: 'short',
						month: 'short',
						day: 'numeric',
					})}
				>
					<label htmlFor={`${name}-input`} className="hidden-input-label">
						<input type="text" className="hidden-input" id={`${name}-input`} />
					</label>
					<div className="date-picker d-flex align-items-center flex-1" onClick={this.openCalendar}>
						<i className="icon-date-arr" />
						<div className="selected">
							{selectedToDate.toLocaleString(locale, {
								weekday: 'short',
								month: 'short',
								day: 'numeric',
							})}
						</div>
					</div>
				</div>
				<div style={{ position: 'relative' }}>
					<FadeTransition timeout={100} in={openedInput === name}>
						<DatePicker before={selectedFromDate as Date} selected={selectedToDate} onSelectDate={this.onSelectDate} />
					</FadeTransition>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	selectedFromDate: state.flight.selectedFromDate,
	selectedToDate: state.flight.selectedToDate,
	openedInput: state.base.openedInput,
});

export default connect(mapStateToProps, { selectFromDate, selectToDate, openInput })(DatePickerTo);
