import AddHotelsSwitch from 'app/components/Flights/FilterForm/AddHotelsSwitch';
import { ItemType } from 'app/modules/Constants';
import { default as classNames, default as classnames } from 'classnames';
import dynamic from 'next/dynamic';
import React from 'react';
import { TFunction, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setTooltipError } from '../../../actions/baseActions';
import { doSearchFlights, prePopulateDataFromQueryString } from '../../../actions/flightActions';
import Helper from '../../modules/Helper';
import { IAirportItem } from '../Common/AirportSelect/Item';
import TooltipError from '../Common/TooltipError';
import Trans from '../Common/Trans';
import AirportSelectFrom from '../Flight/AirportSelectFrom';
import AirportSelectRevers from '../Flight/AirportSelectRevers';
import AirportSelectTo from '../Flight/AirportSelectTo';
import TripTypes from '../Flight/BaseInputs/TripTypes';
import DatePickerFrom from '../Flight/DatePickerFrom';
import DatePickerTo from '../Flight/DatePickerTo';
import InputPassengers from '../Flight/InputPassengers';
import InputPricingTypes from '../Flight/InputPricingTypes';
import PricingTypes from '../Flight/PricingTypes';
import Confirmation from '../PriceAlert/Confirmation';
import ModalSignUp from '../PriceAlert/ModalSignUp';
import PassengerCounter from './FilterForm/PassengerCounter';

const PriceAlertCheckboxConfirmation = dynamic(() => import('../PriceAlert/PriceAlertCheckboxConfirmation'), {
	ssr: false,
});
const AddHotelsCheckbox = dynamic(() => import('./FilterForm/AddHotelsCheckbox'), {
	ssr: false,
});

type FilterFormProps = {
	priceAlertHandlerChecked: boolean;
	compare?: boolean;
	selectedToLocation?: IAirportItem;
	selectedFromLocation?: IAirportItem;
	selectedTripType: ItemType;
	lng: string;
	t: TFunction;
	addHotelsChecked: boolean;
	prePopulateDataFromQueryString: () => void;
	doSearchFlights: (compare?: boolean) => void;
};
type FilterFormState = {
	autoredirect: boolean;
	renderAddHotels: boolean;
};
class FilterForm extends React.Component<FilterFormProps, FilterFormState> {
	constructor(props: FilterFormProps) {
		super(props);

		this.state = {
			autoredirect: false,
			renderAddHotels: true,
		};
	}
	componentDidMount() {
		this.props.prePopulateDataFromQueryString();
	}
	getButtonText() {
		if (this.props.compare) {
			return 'keywords.compare';
		}

		if (this.props.priceAlertHandlerChecked || (this.props.addHotelsChecked && this.props.priceAlertHandlerChecked)) {
			return 'keywords.signUpAndSearch';
		}
		if (this.props.addHotelsChecked) {
			return 'keywords.searchFlightsAndHotels';
		}

		return 'main.flights.searchBtn';
	}
	doSearch() {
		this.setState({
			autoredirect: true,
		});
		this.props.doSearchFlights(this.props.compare);
	}
	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: Readonly<FilterFormProps>) {
		const parsed = Helper.parseQueryString(window.location.search, true);
		const hasPartnerParameters = parsed.hasOwnProperty('b') || parsed.hasOwnProperty('f');

		if (hasPartnerParameters && !parsed.hasOwnProperty('bvert')) {
			this.setState({
				renderAddHotels: false,
			});
		}

		if (parsed.hasOwnProperty('autoredirect') && parsed.autoredirect === '1' && !this.state.autoredirect) {
			if (parsed.hasOwnProperty('destcode')) {
				if (!Helper.isEmpty(nextProps.selectedToLocation) && !Helper.isEmpty(nextProps.selectedFromLocation)) {
					this.doSearch();
				}
				return;
			} else if (!Helper.isEmpty(nextProps.selectedFromLocation)) {
				this.doSearch();
			}
		}
	}

	handleSearch = () => {
		this.props.doSearchFlights(this.props.compare);
	};

	render() {
		const isOneWay = this.props.selectedTripType.value === 'one-way';
		const isRtlLanguage = Helper.isRtlLanguage(this.props.lng as string);
		const { renderAddHotels } = this.state;
		const { priceAlertHandlerChecked, lng, addHotelsChecked, t } = this.props;

		return (
			<>
				<form
					id="redirect-back-form"
					method="post"
					action={process.env.NEXT_PUBLIC_BACKEND_URL + '/flights/redirect'}
				/>
				<div id="filter-form">
					<div className="inline-inputs" id="base-inputs">
						<TripTypes />
						<div className="input d-none d-xl-block">
							<PricingTypes />
						</div>
						<div className="input d-none d-xl-block">
							<PassengerCounter name="passengers-counter" placeholder={t('keywords.passengers')} />
						</div>
					</div>
					<div className="search-form row">
						<div className="col pr-lg-1">
							<div className="row main-inputs">
								<div
									className={classnames('col-xl-3 col-md-6 ', {
										'col-12 col-md-12 col-xl-4': isOneWay,
									})}
								>
									<TooltipError labelClass="d-block d-xl-none" label={t('keywords.from')} flexOne name="airport-from">
										<AirportSelectFrom name="airport-from" />
									</TooltipError>
								</div>
								<div className="col-1 d-xl-block d-none" style={{ maxWidth: '62px' }}>
									<TooltipError name="airport-reverse">
										<AirportSelectRevers />
									</TooltipError>
								</div>
								<div
									className={classnames('col-xl-3 col-md-6 ', {
										'col-md-12 col-xl-4': isOneWay,
									})}
								>
									<TooltipError labelClass="d-block d-xl-none" label={t('keywords.to')} flexOne name="airport-to">
										<AirportSelectTo name="airport-to" />
									</TooltipError>
								</div>
								<div
									className={classnames('flex-xl-grow-1 col-xl-2 col-6 xs-input-full', {
										'col-12': isOneWay,
									})}
								>
									<TooltipError labelClass="d-block d-xl-none" label={t('keywords.departing')} name="date-from">
										<DatePickerFrom name="date-from" lng={lng} />
									</TooltipError>
								</div>

								{!isOneWay ? (
									<div className="flex-xl-grow-1 col-xl-2 col-lg-6 col-6 xs-input-full">
										<TooltipError labelClass="d-block d-xl-none" label={t('keywords.returning')} name="date-to">
											<DatePickerTo name="date-to" lng={lng} />
										</TooltipError>
									</div>
								) : (
									''
								)}
							</div>
							<div className="row main-inputs d-xl-none">
								<div className="d-xl-none col-6">
									<TooltipError labelClass="d-block d-xl-none" label={t('keywords.class')} name="input-pricing-types">
										<InputPricingTypes name="input-pricing-types" />
									</TooltipError>
								</div>

								<div className="d-xl-none col-6">
									<TooltipError
										labelClass="d-block d-xl-none"
										label={t('keywords.travelers')}
										name="mobile-input-passengers"
									>
										<InputPassengers name="mobile-input-passengers" />
									</TooltipError>
								</div>
							</div>
						</div>
						{renderAddHotels && <AddHotelsSwitch lng={lng} addHotelsChecked={addHotelsChecked} onlyMobile />}
						<Confirmation onlyMobile />

						<div id="search-button-container" className="col-xl-2 col-12 pr-lg-1">
							<button
								id="search-button"
								type="button"
								onClick={this.handleSearch}
								className={classnames('btn', {
									'price-alert-handler-checked': priceAlertHandlerChecked || addHotelsChecked,
								})}
							>
								<span className="search-button-text">
									<Trans i18nKey={this.getButtonText()} />
								</span>
							</button>
						</div>
					</div>
					<div className={classNames('row', { 'flex-row-reverse': isRtlLanguage })}>
						<div className="col-xl col-md-12 pr-1">
							<div
								style={{ width: '100%' }}
								className={classNames('mt-2 d-flex align-items-center', {
									'justify-content-between': !isRtlLanguage,
								})}
							>
								<div />
								<div
									className={classnames('d-xl-flex d-none', {
										'flex-row-reverse': isRtlLanguage,
									})}
								>
									{renderAddHotels && <AddHotelsCheckbox lng={lng} addHotelsChecked={addHotelsChecked} />}
									<PriceAlertCheckboxConfirmation lng={lng} priceAlertHandlerChecked={priceAlertHandlerChecked} />
								</div>
							</div>
						</div>
						<div className="col-xl-2 col-12 pr-lg-1"></div>
					</div>
				</div>
				<ModalSignUp />
			</>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	selectedTripType: state.flight.selectedTripType,
	priceAlertHandlerChecked: state.base.priceAlertHandlerChecked,
	selectedToLocation: state.flight.selectedToLocation,
	selectedFromLocation: state.flight.selectedFromLocation,
	addHotelsChecked: state.flight.addHotelsChecked,
	lng: state.base.lng,
});

export default withTranslation()(
	connect(mapStateToProps, { setTooltipError, doSearchFlights, prePopulateDataFromQueryString })(FilterForm)
);
