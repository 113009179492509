import { AxiosResponse } from 'axios';
import React from 'react';
import { connect } from 'react-redux';
import { openInput, setLocationByIATACode } from '../../../actions/baseActions';
import { selectFromLocation } from '../../../actions/flightActions';
import { FLIGHT_SELECT_FROM_LOCATION } from '../../../actions/types';
import ApiInterface from '../../modules/ApiInterface';
import Helper from '../../modules/Helper';
import AirportSelect from '../Common/AirportSelect';
import { IAirportItem } from '../Common/AirportSelect/Item';

type AirportSelectFromProps = {
	name: string;
	selectedFromLocation?: IAirportItem;
	autoJump?: boolean;
	preventFocus?: boolean;
	preventSetInputBoxClassName?: boolean;
	selectFromLocation: (item: IAirportItem | object) => void;
	openInput: (name?: string | null) => void;
	setLocationByIATACode: (location: string, type: string) => void;
};
type AirportSelectFromState = {
	checkedDestCode: boolean;
};

class AirportSelectFrom extends React.Component<AirportSelectFromProps, AirportSelectFromState> {
	constructor(props: AirportSelectFromProps) {
		super(props);
		this.state = {
			checkedDestCode: false,
		};
	}

	onSelect = (location: IAirportItem | object) => {
		this.props.selectFromLocation(location);
		if (this.props.autoJump) {
			this.props.openInput('airport-to');
		} else if (!this.props.preventFocus) {
			this.props.openInput();
		}
	};

	componentDidMount() {
		setTimeout(() => {
			const parsed = Helper.parseQueryString(window.location.search, true);
			const origcode = parsed.origcode;
			if (Helper.isEmpty(this.props.selectedFromLocation)) {
				document.getElementById(this.props.name + '-input')?.focus();
				// @ts-ignore
				document.getElementById(this.props.name + '-input')?.select();
			}
			if (!!origcode && typeof origcode === 'string' && origcode.length === 3 && origcode !== 'None') {
				// @ts-ignore
				this.props.setLocationByIATACode(origcode.toUpperCase(), FLIGHT_SELECT_FROM_LOCATION);
			}
		}, 1);
	}

	// @ts-ignore
	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: AirportSelectFromProps, nextContext) {
		if (!Helper.isEmpty(nextProps.selectedFromLocation)) {
			const inputElement = document.getElementById(this.props.name + '-input') as HTMLInputElement;
			if (inputElement) {
				inputElement.disabled = true;
				inputElement.disabled = false;
			}
		}
		const parsed = Helper.parseQueryString(window.location.search, true);
		const destcode = parsed.destcode;

		if (this.state.checkedDestCode) {
			return;
		}

		this.setState(
			{
				checkedDestCode: true,
			},
			() => {
				if (!!destcode && destcode !== 'undefined' && destcode !== 'None' && destcode.length === 3) {
					console.log('airport select from', parsed.destcode);

					ApiInterface.instance
						.fetchAirport(destcode)
						.then((res: AxiosResponse) => {
							if (Helper.isEmpty(res.data) && !this.props.preventFocus) {
								const airportToInput = document.getElementById('airport-to-input') as HTMLInputElement;
								if (airportToInput) {
									airportToInput.focus({ preventScroll: true });
									airportToInput.select();
								}
							}
						})
						.catch(() => {});
				} else if (!Helper.isEmpty(nextProps.selectedFromLocation) && !this.props.preventFocus) {
					const airportToInput = document.getElementById('airport-to-input') as HTMLInputElement;
					airportToInput?.focus({ preventScroll: true });
					airportToInput?.select();
				}
			}
		);
	}

	render() {
		return (
			<AirportSelect
				i18nValidationDropDownKey="validation.flights.fromLocationSelectDropDown"
				preventSetInputBoxClassName={this.props.preventSetInputBoxClassName}
				icon="icon-departure-copy"
				placeholder={Helper.trans('main.flights.fromInputPlaceholder')}
				selected={this.props.selectedFromLocation}
				name={this.props.name}
				onSelect={this.onSelect}
			/>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	selectedFromLocation: state.flight.selectedFromLocation,
	autoJump: state.base.autoJump,
	preventFocus: state.base.preventFocus,
});

export default connect(mapStateToProps, { selectFromLocation, openInput, setLocationByIATACode })(AirportSelectFrom);
