import { ItemType, tripTypes } from 'app/modules/Constants';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { selectTripType } from '../../../../actions/flightActions';
import Helper from '../../../modules/Helper';
import BasicDropDown from '../../Common/BasicDropDown';

type TripTypesProps = {
	selectedTripType?: ItemType;
	selectTripType: (item: ItemType) => void;
};

const TripTypes: FC<TripTypesProps> = (props) => {
	const { t } = useTranslation();
	const onSelect = (item: ItemType) => {
		props.selectTripType(item);
	};

	useEffect(() => {
		const parsed = Helper.parseQueryString(window.location.search, true);
		if (parsed.hasOwnProperty('oneway')) {
			props.selectTripType({
				label: t('keywords.oneWay'),
				value: 'one-way',
			});
		}
	}, []);

	return (
		<div className="input">
			<BasicDropDown
				enableTabMode
				onSelect={onSelect}
				name="trip-type"
				selected={props.selectedTripType}
				placeholder="keywords.tripType"
				items={tripTypes}
			/>
		</div>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	selectedTripType: state.flight.selectedTripType,
});

export default connect(mapStateToProps, { selectTripType })(TripTypes);
