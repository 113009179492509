import { setTooltipError } from 'actions/baseActions';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { selectFromLocation, selectToLocation } from '../../../actions/flightActions';
import Helper from '../../modules/Helper';
import { IAirportItem } from '../Common/AirportSelect/Item';

type AirportSelectReversProps = {
	selectFromLocation: (item?: IAirportItem) => void;
	selectToLocation: (item?: IAirportItem) => void;
	setTooltipError: (name: string, value: string) => void;
	selectedToLocation?: IAirportItem;
	selectedFromLocation?: IAirportItem;
};
const AirportSelectRevers: FC<AirportSelectReversProps> = (props) => {
	const [reverse, setReverse] = React.useState(false);

	const handleReverse = () => {
		if (Helper.isEmpty(props.selectedToLocation) && Helper.isEmpty(props.selectedFromLocation)) return;
		setReverse(!reverse);
		props.selectFromLocation(props.selectedToLocation);
		props.selectToLocation(props.selectedFromLocation);
		props.setTooltipError('airport-to', '');
		props.setTooltipError('airport-from', '');
	};

	return (
		<div onClick={handleReverse} className=" not-flex-1">
			<div className="input-control">
				<i
					style={{
						transition: 'all .2s ease',
						transform: reverse ? 'rotate(180deg)' : undefined,
					}}
					className="icon-switch"
				/>
			</div>
		</div>
	);
};

// @ts-ignore
const mapStateToProps = (state) => ({
	selectedFromLocation: state.flight.selectedFromLocation,
	selectedToLocation: state.flight.selectedToLocation,
});

export default connect(mapStateToProps, { selectFromLocation, selectToLocation, setTooltipError })(AirportSelectRevers);
